import { gql } from 'src/utils/gql/query';

export const videosBasicFragment = gql`
  fragment videosBasic on videos {
    id
    slug
    date_created
    thumbnail {
      id
    }
    bodyParts {
      bodyParts_id {
        slug
      }
    }
    subjects {
      subjects_id {
        slug
      }
    }
    tags {
      tags_id {
        id
        translations {
          name
          languages_code {
            code
          }
        }
      }
    }
    disclaimer
    source {
      id
      url
      format
      duration
      startDate
      endDate
    }
    translations {
      description
      excerpt
      name
      languages_code {
        code
      }
    }
  }
`;
