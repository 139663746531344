import styled, { css } from 'styled-components';
import { media } from 'src/styles/designs';
import { dirRTL, fromTheme } from 'src/styles/theme';
import Image from 'next/image';
import { DirectusImage } from 'src/components/common/DirectusImage';
import { StyleProps } from '@summer/jst-react';
import { ForwardedRef, forwardRef, HTMLAttributes, ReactChild } from 'react';
import { ChevronRight } from 'src/components/icons/ChevronRight';
import { casePathEmptyString } from 'src/utils/casePathEmptyString';
import { lineClamp } from 'src/styles/common';

const ChevronWrapper = styled.div`
  ${media.w.lessThan.desktop} {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
  }
  ${media.w.greaterThanOrEqual.desktop} {
    position: absolute;
    opacity: 0;
    transform: translateX(-15px);
    transition: 0.15s;
    right: -6px;
    bottom: 10px;
    svg {
      height: 20px;
    }
  }
`;

const Wrapper = styled.a`
  position: relative;
  width: 100%;
  height: 270px;
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  color: ${fromTheme((theme) => theme.text.palette.gray6)};
  text-align: left;
  background: ${({ theme }) => theme.palette.neauvia};
  ${dirRTL} {
    text-align: right;
  }

  ${media.w.greaterThanOrEqual.desktop} {
    height: 577px;
    padding: 50px 24px;
    &:hover {
      ${ChevronWrapper} {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &:nth-child(2) {
    margin-top: -30px;
  }

  &:nth-child(3) {
    margin-top: 30px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(22, 22, 22, 0.4) 11%,
      rgba(200, 201, 201, 0) 42%,
      rgba(201, 201, 201, 0) 71%,
      rgba(192, 202, 199, 0) 100%,
      rgba(25, 27, 26, 0) 100%
    );
    z-index: 2;
  }
`;

export const ShowcaseTitle = styled.h4`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 110%;

  ${media.w.greaterThanOrEqual.desktop} {
    font-size: 26px;
  }
`;

export const ShowcaseSubtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 110%;
  margin-top: 10px;
  ${lineClamp(4)};

  ${media.w.greaterThanOrEqual.desktop} {
    font-size: 15px;
  }
`;

const Content = styled.div<{ $withChevron?: boolean }>`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  max-width: 100%;
  word-wrap: break-word;
  ${casePathEmptyString('$withChevron')(
    css`
      ${media.w.greaterThanOrEqual.desktop} {
        padding-right: 20px;
      }
    `,
  )}
`;

const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;

  ${media.w.greaterThanOrEqual.desktop} {
    object-position: center center;
  }
`;

export interface ShowcaseProps {
  title?: string;
  subtitle?: string;
  image: string;
  children?: ReactChild;
  withChevron?: boolean;
  onClick?: () => void;
}

export const Showcase = forwardRef(
  (
    {
      image,
      title,
      subtitle,
      withChevron,
      children,
      ...props
    }: ShowcaseProps & StyleProps & HTMLAttributes<HTMLElement>,
    ref: ForwardedRef<HTMLAnchorElement>,
  ) => (
    <Wrapper {...props} ref={ref}>
      {image && (
        <BackgroundImage as={DirectusImage} src={image} layout="fill" />
      )}
      <Content $withChevron={withChevron}>
        {children || (
          <>
            {title && <ShowcaseTitle>{title}</ShowcaseTitle>}
            {title && subtitle && (
              <ShowcaseSubtitle>{subtitle}</ShowcaseSubtitle>
            )}
            {withChevron && (
              <ChevronWrapper>
                <ChevronRight width={24} height={14} />
              </ChevronWrapper>
            )}
          </>
        )}
      </Content>
    </Wrapper>
  ),
);

Showcase.displayName = 'Showcase';
