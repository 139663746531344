import { useEffect } from 'react';

export const useMutationObserver = (
  domNodeSelector: string,
  callback: MutationCallback,
  observerOptions?: MutationObserverInit,
): void => {
  useEffect(() => {
    const targetNode = document.querySelector(domNodeSelector);

    const observer = new MutationObserver(callback);

    if (targetNode) {
      observer.observe(targetNode, observerOptions);
    }
    return () => {
      observer.disconnect();
    };
  }, [domNodeSelector, observerOptions, callback]);
};
