import { APIs } from 'src/models/apis';
import { useAPIs } from 'src/hooks/useAPIs';
import { useQuery } from 'react-query';
import { QueryKey } from 'src/models/query';
import { businessCaseVideosGqlQuery } from 'src/modules/home/graphql/businesscases-videos.query';

export const fetchBusinessCaseVideos = async (apis: APIs, limit?: number) => {
  const directus = await apis.directus();

  return directus
    .query(businessCaseVideosGqlQuery, {
      filter: {
        subjects: { subjects_id: { slug: { _eq: 'experts-talks' } } },
        speakers: {
          people_id: { portrait: { id: { _nnull: true } } },
        },
      },
      limit,
      sort: ['-date_created'],
    })
    .then((d) =>
      d.videos
        .filter((video) => video && video.speakers.length > 0)
        .map((video) => ({
          ...video,
          speakers: video.speakers.sort(
            (a, b) => a.people_id.sort - b.people_id.sort,
          ),
        })),
    );
};

export const useBusinessCasesVideosQuery = (limit?: number) => {
  const apis = useAPIs();

  return useQuery([...QueryKey.BusinessCaseVideos, limit], () =>
    fetchBusinessCaseVideos(apis, limit),
  );
};
