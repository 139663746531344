import { useCallback, useRef, useState } from 'react';
import asap from 'asap';

type Transition<Data, States extends string> = {
  when?: States | Array<States>;
  if: Partial<Data> | ((prev: Data, next: Data) => boolean);
  then: States;
  break?: true;
};

type FSMTransitions<Data, States extends string> = Array<
  Transition<Data, States>
>;

type FSMTransitionsMap<Data, States extends string> = Partial<
  Record<States | '', FSMTransitions<Data, States>>
>;

const contains = <T>(a: T, b: Partial<T>) => {
  if (a === b) {
    return true;
  }
  for (const key in b) {
    if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
};

function findTransition<Data, States extends string>(
  transitions: Transition<Data, States>[],
  prev: Data,
  current: Data,
): Transition<Data, States> | undefined {
  const matchingTransition = transitions.find((t) =>
    typeof t.if === 'function' ? t.if(prev, current) : contains(current, t.if),
  );
  return matchingTransition;
}

const crunchState = <Data, States extends string>(
  transitions: FSMTransitionsMap<Data, States>,
  currentState: States,
  previousData: Data,
  currentData: Data,
): [States, boolean | undefined] => {
  const currentStateTransitions: Array<Transition<Data, States>> | undefined =
    transitions[currentState];
  const catchAllStateTransitions = transitions[''] || [];

  const currentTransitions = currentStateTransitions
    ? [...currentStateTransitions, ...catchAllStateTransitions]
    : catchAllStateTransitions;

  const foundTransition = findTransition<Data, States>(
    currentTransitions,
    previousData,
    currentData,
  );
  return foundTransition
    ? [foundTransition.then, foundTransition.break]
    : [currentState, false];
};

/**
 * Compute component state from data.
 * FSM starts when component mounts.
 * Can be reset when component is mounted by:
 * * supplying true to the second parameter of generated hook function.
 * * calling reset function, returned from hook as third item.
 *
 * Example: https://codesandbox.io/s/awesome-ishizaka-53pmv

 * @param transitions
 * @param initialState
 */
export const createUseFSM = <Data, States extends string>(
  transitions: FSMTransitions<Data, States>,
  initialState: States,
) => {
  const transitionsMap: FSMTransitionsMap<Data, States> = {};
  for (const transition of transitions) {
    let prevStates: Array<States | ''> = [''];
    if (transition.when) {
      if (typeof transition.when === 'string') {
        prevStates = [transition.when];
      } else {
        prevStates = transition.when;
      }
    }
    for (const prevState of prevStates) {
      if (!transitionsMap[prevState]) {
        transitionsMap[prevState] = [];
      }
      const transitions = transitionsMap[prevState]!;
      transitions.push(transition);
    }
  }

  return (
    newData: Data,
    onStateChanged?: (
      state: States,
      updateState: (updateStateFunction: () => void) => void,
    ) => void,
    reset = false,
  ): [States, boolean, () => void] => {
    const state = useRef({ state: initialState, data: newData });
    const [, forceUpdate] = useState(false);
    const doReset = useCallback(
      (update = true) => {
        state.current.data = newData;
        state.current.state = initialState;
        if (update) {
          forceUpdate((v) => !v);
        }
      },
      [newData, forceUpdate],
    );

    if (reset) {
      doReset(false);
    }

    const cnt = useRef(0);

    while (true) {
      if (cnt.current === 100) {
        console.error('[createUseFSM] FSM loop detected', state.current);
        throw new Error('[createUseFSM] FSM loop detected');
      }
      let [nextState, breakLoop] = crunchState(
        transitionsMap,
        state.current.state,
        state.current.data,
        newData,
      );
      state.current.data = newData;

      if (nextState === state.current.state) {
        break;
      }

      if (onStateChanged) {
        onStateChanged(nextState, (updateStateFunction: () => void) => {
          asap(updateStateFunction);
          breakLoop = true;
        });
      }

      cnt.current++;
      state.current.state = nextState;
      if (breakLoop) {
        break;
      }
    }

    const justEnteredState = cnt.current > 0;

    cnt.current = 0;

    return [state.current.state, justEnteredState, doReset];
  };
};
