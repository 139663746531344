import { useAPIs } from 'src/hooks/useAPIs';
import { useQuery } from 'react-query';
import { QueryKey } from 'src/models/query';
import { APIs } from 'src/models/apis';
import { protocolsGqlQuery } from 'src/modules/protocols/graphql/protocol.query';

export const protocolsQuery = (apis: APIs) => ({
  queryKey: [...QueryKey.Protocols],
  queryFn: async () => {
    const directus = await apis.directus();

    return directus.query(protocolsGqlQuery).then((d) =>
      d.protocols.sort((a, b) => {
        const order = ['nboost', 'nlift', 'nrose'];

        const aIndex = order.indexOf(a.slug);
        const bIndex = order.indexOf(b.slug);

        return aIndex - bIndex;
      }),
    );
  },
});

export const useProtocolsQuery = () => {
  const apis = useAPIs();

  return useQuery(protocolsQuery(apis));
};
