import { Videos, Videos_Filter } from 'src/graphql/schema.graphql';
import { gql, GqlFragment } from 'src/utils/gql/query';

export const businessCaseVideosGqlQuery: GqlFragment<
  { videos: Videos[] },
  {
    filter: Videos_Filter;
    sort: string[];
    limit: number;
  }
> = gql`
  query BusinessCaseVideosQuery(
    $filter: videos_filter
    $sort: [String]
    $limit: Int
  ) {
    videos(filter: $filter, limit: $limit, sort: $sort) {
      slug
      bodyParts {
        bodyParts_id {
          slug
        }
      }
      subjects {
        subjects_id {
          slug
        }
      }
      speakers {
        people_id {
          name
          sort
          portrait {
            id
          }
          translations {
            title
            degree
            languages_code {
              code
            }
          }
        }
      }
    }
  }
`;
