import { HTMLAttributes, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { last } from 'fp-ts/Array';
import { toUndefined } from 'fp-ts/Option';
import { layer } from 'src/styles/common';
import { HeroLoaderFillLayer } from 'src/components/common/Loader';
import { useInView } from 'react-hook-inview';
import { CommonFormFieldProps } from 'src/components/common/formInput.types';
import { commonInputToFastField } from 'src/utils/formik/commonInputToFastField';
import { casePathEmptyString } from 'src/utils/casePathEmptyString';
import { useSupportsWebP } from 'vendor/hooks/useSupportsWebP';
import { coerceArray } from 'src/utils/coerceArray';
import dynamic from 'next/dynamic';

export const FaceComponentContainer = styled.div<{
  $value: string[] | undefined;
}>`
  svg {
    ${layer};
  }

  svg switch g > use {
    pointer-events: none;
  }

  svg switch > g > g[tabindex] {
    & > g {
      pointer-events: none;
      opacity: 0;
      will-change: opacity, filter;
      transition: all ${({ theme }) => theme.animation.duration}s
        ${({ theme }) => theme.animation.easing.decelerated.toString()};
    }

    & > path:last-child {
      opacity: 0;
    }

    & > path {
      will-change: fill;
      transition: all ${({ theme }) => theme.animation.duration * 2}s
        ${({ theme }) => theme.animation.easing.decelerated.toString()};
      fill: #828282;
    }

    & > :last-child {
      //opacity: 0;
      cursor: pointer;
    }
  }

  svg switch > g > g:hover,
  svg switch > g > g:focus {
    & > path:not([opacity]) {
      fill: ${({ theme }) => theme.palette.neauvia} !important;
    }
  }

  ${({ $value }) => {
    return (
      $value &&
      $value.map(
        (value) => css`
          svg switch > g > g[id$='${value}'] {
            & > g:not([opacity]) {
              opacity: 1;
            }
            & > g[opacity] {
              opacity: 0.6;
            }
            & > path {
              fill: #ffffff;
            }
          }
        `,
      )
    );
  }}
`;

const FaceComponentWrapper = styled.div`
  ${layer};
`;

const FaceComponentSizer = styled.div<{ $small: boolean }>`
  margin-top: 20px;
  position: relative;
  padding-top: 121.2471132%;

  ${({ $small }) =>
    $small
      ? css`
          --blur-size: 10rpx;
        `
      : css`
          --blur-size: 20rpx;
        `};

  &:before {
    filter: blur(var(--blur-size));
    content: '';
    ${layer};
    display: block;
    background: url('${require('!!url-loader!./loading-bg.png').default}');
    background-size: contain;
    background-repeat: no-repeat;

    will-change: opacity;
  }

  ${casePathEmptyString('$fromCache')(
    css`
      &:before {
        display: none;
      }
    `,
  )}
`;

type FaceComponentProps = {
  small?: boolean;
} & (
  | {
      multi: true;
      value?: string[];
    }
  | {
      multi?: false;
      value?: string;
    }
);

const LazyLoRez = dynamic(() => import('./face-258@2x.webp.svg'));
const LazyHiRez = dynamic(() => import('./face-642@2x.webp.svg'));
const LazyLoRezFallback = dynamic(() => import('./face-258@2x.png.svg'));
const LazyHiRezFallback = dynamic(() => import('./face-642@2x.png.svg'));

const FaceComponent = ({
  small,
  onChange,
  value,
  multi,
  ...props
}: Omit<HTMLAttributes<HTMLDivElement>, keyof CommonFormFieldProps> &
  Omit<CommonFormFieldProps<unknown, string[] | string>, 'value'> &
  FaceComponentProps) => {
  const [intersectionRef, isVisible] = useInView({
    threshold: 0,
    unobserveOnEnter: true,
  });
  const supportsWebP = useSupportsWebP();

  const Component = useMemo(() => {
    if (supportsWebP !== null && isVisible) {
      return supportsWebP
        ? small
          ? LazyLoRez
          : LazyHiRez
        : small
        ? LazyLoRezFallback
        : LazyHiRezFallback;
    }
    return null;
  }, [isVisible, small, supportsWebP]);

  const onClick = useCallback(
    (e: { target: EventTarget }) => {
      const target = e.target as HTMLElement;

      const elementId = target.matches('g[id]')
        ? target.id
        : target.matches('g[tabindex][id] *')
        ? target.parentElement!.id
        : null;
      if (elementId) {
        const id: string = toUndefined(last(elementId.split('__')))!;

        if (multi) {
          let result;
          if (value == null) {
            result = [id];
          } else {
            const coerced = coerceArray(value);
            if (coerced.length === 1 && coerced[0] === id) {
              result = undefined;
            } else if (coerced.includes(id)) {
              result = coerced.filter((v) => v !== id);
            } else {
              result = [...coerced, id];
            }
          }
          onChange?.({ target: { value: result } } as any);
        } else {
          const currentValue = value as string | undefined;
          if (currentValue === id) {
            onChange?.({ target: { value: undefined } } as any);
          } else {
            onChange?.({ target: { value: id } } as any);
          }
        }
      }
    },
    [value, onChange, multi],
  );

  return (
    <FaceComponentContainer
      {...props}
      ref={intersectionRef}
      $value={Array.isArray(value) ? value : value ? [value] : undefined}
      onKeyDown={(e) => {
        if (e.key === ' ' || e.key === 'Enter') {
          e.preventDefault();
          onClick(e);
        }
      }}
      onClick={onClick}
    >
      <FaceComponentSizer $small={!!small}>
        <FaceComponentWrapper>
          {Component ? <Component /> : <HeroLoaderFillLayer />}
        </FaceComponentWrapper>
      </FaceComponentSizer>
    </FaceComponentContainer>
  );
};

export default FaceComponent;

export const FaceComponentFormik = commonInputToFastField(FaceComponent, {
  useTargetValue: true,
  touchOnChange: true,
});
