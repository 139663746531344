import { useState } from 'react'
import { useIsomorphicLayoutEffect, useMountedState } from 'react-use'

let cache: boolean | null = null

export function useSupportsWebP(): boolean | null {
  const isMounted = useMountedState()
  const [{ webP: supportsWebP }, setWebPSupport] = useState({
    webP: cache,
  })

  useIsomorphicLayoutEffect(() => {
    if (cache !== null) return
    const checkForSupport = async (): Promise<void> => {
      const browserSupportsWebP = await require('supports-webp')
      cache = browserSupportsWebP

      if (!isMounted()) return
      setWebPSupport({ webP: browserSupportsWebP })
    }

    checkForSupport()
  }, [cache])

  return supportsWebP
}
