import { useCallback, useState } from 'react';
import { useMutationObserver } from 'src/hooks/useMutationObserver';

const checkIsRTL = () => {
  return typeof document !== 'undefined'
    ? document.documentElement.dir === 'rtl'
    : false;
};

const options: MutationObserverInit = { attributes: true };

export const useIsRTL = (): boolean => {
  const [isRTL, setIsRTL] = useState(checkIsRTL);

  const handler: MutationCallback = useCallback((mutationList) => {
    mutationList.forEach(() => {
      setIsRTL(checkIsRTL);
    });
  }, []);

  useMutationObserver('html', handler, options);

  return isRTL;
};
