import { Protocols } from 'src/graphql/schema.graphql';
import { gql, GqlFragment } from 'src/utils/gql/query';

export const protocolsGqlQuery: GqlFragment<{ protocols: Protocols[] }> = gql`
  query {
    protocols {
      id
      slug
      logo {
        id
      }
      preview {
        id
      }
      previewMobile {
        id
      }
      tags {
        tags_id {
          id
          translations {
            name
            languages_code {
              code
            }
          }
          slug
        }
      }
      translations {
        name
        languages_code {
          name
          code
        }
      }
    }
  }
`;
