import { FC, useContext, useEffect } from 'react';
import { FormikContext } from 'formik';
import { FormikContextType } from 'formik/dist/types';
import { createUseFSM } from 'src/utils/formik/createUseFSM';

const useFormikValidationFSM = createUseFSM<
  FormikContextType<any>,
  | 'validating'
  | 'validated'
  | 'invalid'
  | 'submitting'
  | 'newValues'
  | 'initial'
>(
  [
    {
      when: 'validating',
      if: {
        isValidating: false,
        isValid: true,
      },
      then: 'validated',
    },
    {
      when: 'validating',
      if: {
        isValidating: false,
        isValid: false,
      },
      then: 'invalid',
    },
    {
      when: 'newValues',
      if: {
        isValidating: true,
      },
      then: 'validating',
    },
    {
      when: 'submitting',
      if: {
        isSubmitting: false,
      },
      then: 'initial',
    },
    {
      if: (prevData, nextData) => prevData.values !== nextData.values,
      then: 'newValues',
    },
    {
      if: (prevData, nextData) => prevData.values === nextData.initialValues,
      then: 'initial',
    },
    {
      if: {
        isSubmitting: true,
      },
      then: 'submitting',
    },
  ],
  'initial',
);

export const FormikAutoSubmit: FC = () => {
  const formik = useContext(FormikContext);

  const [state, justEnteredState] = useFormikValidationFSM(formik);

  useEffect(() => {
    if (justEnteredState && state === 'validated') {
      formik.submitForm();
    }
  }, [state, justEnteredState]);
  return null;
};
