import { useMemo } from 'react';

/**
 * Fixes fast refresh (or hot reload), has no effect on production.
 * Set returned value as key to the element you want to force-remount after refresh.
 */
export function useFastRefreshFixer() {
  return process.env.NODE_ENV !== 'production'
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useMemo(() => Math.random(), [])
    : undefined;
}
